import "amazon-connect-streams";
import { useEffect, useRef } from "react";
import { useDocumentTitle } from "usehooks-ts";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { ColorModeSwitcher } from "@/components/ColorModeSwitcher";
import { Layout } from "@/components/Layout";
import { TitleBar } from "@/components/TitleBar";

const SettingsRoute = function () {
  useDocumentTitle("Back Office — Settings");
  const toot = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!toot.current) return;

    try {
      connect.core.initCCP(toot.current, {
        ccpUrl: "https://leon-test-connect.my.connect.aws/ccp-v2/",
        region: "us-east-1",
      });
      connect.core.onIframeRetriesExhausted(() => {
        console.log("We have run out of retries to reload the CCP Iframe");
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  return (
    <Layout>
      <TitleBar title="Settings" />
      <Layout.Content>
        <br />
        <Typography variant="h6">Appearance</Typography>
        <Stack spacing={2} direction="row" sx={{ p: 1 }}>
          <ColorModeSwitcher />
        </Stack>
        <div id="toot" ref={toot} />
      </Layout.Content>
    </Layout>
  );
};

export default SettingsRoute;
